<template>
  <div class='equipment'
       id='ruleWrapper'>
    <!-- <div class="breadcrumb"></div> -->
    <div class='e_content'>
      <div class='ruleMessage1'>
        <div class='title'>计费规则信息</div>
        <el-form :model="formInline"
                 :rules="rules2"
                 ref="ruleForm2"
                 label-width="100px"
                 class="demo-ruleForm">
          <div class='f-top'>
            <el-form-item :label="$t('searchModule.Rule_Name')"
                          prop="feeName"
                          :disabled='false'>
              <el-input type="text"
                        placeholder="请输入规则名称"
                        v-model.trim="formInline.feeName"
                        auto-complete="off"
                        style='width:200px'></el-input>
            </el-form-item>
            <el-form-item label="规则编码"
                          prop="feeCode"
                          :disabled='false'>
              <el-input placeholder="请输入规则编码"
                        v-model.trim="formInline.feeCode"
                        style='width:200px'></el-input>
            </el-form-item>
            <el-form-item label="描述"
                          prop="description">
              <el-input type="textarea"
                        :maxlength='150'
                        :rows="8"
                        placeholder="请输入描述"
                        v-model="formInline.description"
                        style='width:370px'></el-input>
            </el-form-item>
            <el-form-item label="免费分钟数"
                          prop="freeTime"
                          :disabled='false'>
              <el-input placeholder="请输入免费分钟数"
                        v-model.trim="formInline.freeTime"
                        style='width:200px'></el-input><span>单位：分钟，免费停车时间</span>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Merchant_affiliation')"
                          prop='operationId'>
              <el-select :disabled='this.isEdit'
                         v-model.trim="formInline.operationId"
                         filterable
                         size="15">
                <el-option :label="value.operationName"
                           :value="value.operationId"
                           :key="value.operationId"
                           v-for="value in tenantList"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class='ruleMessage'
               v-for='(rule, index) in formInline.rules'
               :key='rule.cid'>
            <!-- 设置生效日期 -->
            <div class='timerPiker'>
              <el-form-item label="生效日期"
                            :rules='[{
                                      required: true,
                                      message: "不能为空！",
                                      trigger: "change"
                                    }]'>
                <el-date-picker :disabled='rule.dis'
                                v-model.trim="rule.cdate"
                                type="date"
                                value-format='yyyy-MM-dd'
                                placeholder="选择日期"
                                @change='timeChange(rule, index)'
                                :picker-options="pickerOptions0">
                </el-date-picker>
                <span v-if='!index'
                      @click='addRule(index)'
                      style='float:right'
                      class='addEffictDate'>添加生效日期</span>
                <span v-if='index && !rule.dis'
                      class='iconfont icon-shanchu-copy'
                      style='float:right'
                      @click='removeRule(rule)'
                      :style="{'border-color':'red', 'color':'red', 'font-size': '35px'}"></span>
              </el-form-item>
              <div class='errMsg'
                   style='padding-top:5px'>{{rule.errMsg}}</div>
            </div>
            <!-- 设置时段 -->
            <div class='dateForm'
                 v-for="(detail, index1) in rule.details"
                 :key='index1'
                 style='margin-top:50px'>
              <el-form-item label="时段">
                <el-tooltip placement="top"
                            class='tooltip1'>
                  <div slot="content">
                    时段：时段总和必须为24小时，例如：时段1+时段2 = 24小时 <br />
                  </div>
                  <span class='help'>?</span>
                </el-tooltip>
                <el-time-picker :disabled='rule.dis'
                                value-format='HH:mm:ss'
                                v-model="detail.tStart"
                                placeholder="任意时间点"
                                :picker-options='{format: "HH:mm"}'
                                @change='timeHandle(detail)'> </el-time-picker> to
                <el-time-picker :disabled='rule.dis'
                                value-format='HH:mm:ss'
                                v-model="detail.tEnd"
                                placeholder="任意时间点"
                                :picker-options='{format: "HH:mm"}'
                                @change='timeHandle(detail)'> </el-time-picker>
                <span v-if='!rule.dis'
                      @click='detailOpreate(index,index1,detail)'
                      :class='index1 ? "iconfont icon-shanchu-copy" : "iconfont icon-jia" '
                      :style="{'border-color':index1 ? 'red':'blue', 'color': index1 ? 'red':'blue', 'font-size': '30px'}"></span>
              </el-form-item>
              <!-- 区间模块 -->
              <div class='range top'
                   v-for='(item, index2) in detail.items'
                   :key='index2'>
                <el-form-item :label="'区间' + (1 + index2)"
                              :rules='{required: true, message: "必选"}'>
                  <!-- <el-input :disabled='rule.dis' v-show='false' v-model="item.segment" style='width:110px;margin-left:5px' placeholder="区间分钟总数"></el-input> -->
                  <el-select :disabled='rule.dis || !rule.dis && index2 != detail.items.length -1'
                             v-model="item.type"
                             style='width:110px'
                             @change='detailChange($event, detail, item, index2)'>
                    <el-option label="基础时段"
                               :value="0"></el-option>
                    <el-option label="周期计价"
                               :value="2"></el-option>
                    <!-- 上一个时段如果是基础时段 则不能选择按次 -->
                    <el-option label="按次计费"
                               :value="1"></el-option>
                  </el-select>
                  <el-input :disabled='rule.dis'
                            v-model="item.unit"
                            style='width:110px'
                            placeholder="区间单价"></el-input>
                  <span>元/<span v-if='item.type == 1'>次</span>
                    <el-input :disabled='rule.dis'
                              v-if='item.type != 1'
                              v-model="item.period"
                              style='width:100px;margin-left:5px'></el-input><span v-if='item.type != 1'>分钟</span>
                  </span>
                  <span v-if='!rule.dis'
                        @click='itemOpreate(index,index1,index2,item)'
                        v-show='index2!=0 || detail.items[detail.items.length - 1].type == 0'
                        :class='index2 ? "iconfont icon-shanchu-copy" : "iconfont icon-jia" '
                        :style="{'border-color':index2 ? 'red':'blue', 'color': index2 ? 'red':'blue', 'font-size': '30px'}"></span>
                </el-form-item>
              </div>
              <el-form-item class='timeMax'
                            :prop="'rules[' + index + '].details[' + index1 + '].limit'"
                            label="时段最高收费金额："
                            label-width='150px'
                            v-if='detail.items[0].type != 1'
                            :rules="[{required: true, message: '请输入金额', trigger: 'blur'},
                                      {validator: checkNum, trigger: 'blur'}]">
                <el-input :disabled='rule.dis'
                          :maxlength='50'
                          v-model.trim="detail.limit"
                          style='width:200px'></el-input>
                “0”表示没有最高限额
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <div style='margin:0 auto;width:200px;margin-bottom:20px;'>
        <el-button type="primary"
                   @click="submitData()">提交</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    let checkFreeTime = (rule, value, callback) => {
      if (!/(\d+.\d+)|\d+/.test(value)) {
        callback(new Error('必须为数字'));
      } else if (value < 0 || value > 59) {
        callback(new Error('数字必须在0-59之间'));
      } else {
        callback();
      }
    }
    let checkCode = (rule, value, callback) => {
      value = value.replace(/\s/g, '');
      if (/[\u4E00-\u9FA5]/.test(value)) {
        return callback(new Error('编码不能存在汉字'))
      }
      if (!value) {
        callback(new Error('规则编码不能为空'));
      } else if (value.length < 2 || value.length > 50) {
        callback(new Error('规则编码长度在2-50之间'));
      } else {
        callback();
      }
    };
    let checkName = (rule, value, callback) => {
    var regEn = /[`~!@#$%^&*()_+<>?:"{},./;'[\]]/im,
    regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;
      if (!value) {
        callback(new Error('规则名称不能为空'));
      } else if (value.length < 2 || value.length > 50) {
        callback(new Error('规则名称长度在2-50之间'));
      } else if (regEn.test(value) || regCn.test(value)) {
        callback(new Error('名称不能包含特殊字符'));
      } else {
        callback();
        // this.$axios.get('/acb/2.0/fee/byFeeName/' + value).then(res => {
        //         if (res.state != 0) {
        //             if (this.isEdit && this.oldName == value) {
        //                 callback();
        //             } else {
        //                 callback(new Error('规则名称已经存在'));
        //             }
        //         } else {
        //             callback();
        //         }
        // })
      }
    }
    return {
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      oldFormInline: {},
      timeCheck: true,
      showAdd: true,
      first1: false,
      first2: false,
      isEdit: false,
      Dateflag: true,
      feeId: '',
      errMsg: '',
      equipmentId: '',
      tenantList: [],
      formInline: {
        id: 100,
        description: '',
        feeName: '',
        operationId: '',
        freeTime: '0',
        feeCode: '',
        rules: [
          {
            errMsg: '',
            cid: '0',
            date: '',
            cdate: '',
            details: [
              {
                tStart: '',
                tEnd: '',
                cid: '0-0',
                start: '',
                end: '',
                limit: '0',
                items: [
                  {
                    cid: '0-0-0',
                    type: 0,
                    // segment: '',
                    threshold: 0,
                    period: 0,
                    unit: ''
                  }
                ]
              }
            ]
          }
        ]
      },
      rules2: {
        feeName: [
          {
            required: true,
            message: '请输入规则名称',
            trigger: 'blur'
          },
          { validator: checkName, trigger: 'blur' }
        ],
        feeCode: [
          {
            required: true,
            message: '请输入规则编码',
            trigger: 'blur'
          },
          { validator: checkCode, trigger: 'blur' }
        ],
        freeTime: [
          {
            required: true,
            message: '请输入免费分钟数',
            trigger: 'blur'
          }, { validator: checkFreeTime, trigger: 'blur' }
        ],
        operationId: [
          {
            required: true,
            message: '请选择商户',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    detailChange(value, detail, item, index) {
      if (value == 1) {
        item.period = 0;
      }
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get('/acb/2.0/operation/nameList').then(res => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      })
    },
    checkEffectiveDate() {
      for (let i in this.formInline.rules) {
        if (this.formInline.rules[i].cdate == '' || this.formInline.rules[i].cdate == undefined) {
          this.formInline.rules[i].errMsg = '生效日期不能为空'
          return false;
        } else {
        }
      }
      return true;
    },
    checkIsNull(data) {
      for (let i in data.rules) {
        for (let j in data.rules[i].details) {
          let detail = data.rules[i].details[j];
          if (detail.start == '') {
            this.$alert('时段开始时间不能为空或为0！！', this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
            return false;
          }
          if (detail.end == '') {
            this.$alert('时段结束时间不能为空或为0！！', this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
            return false;
          }

          let diff = (new Date('2018/1/1 ' + detail.end) - new Date('2018/1/1 ' + detail.start)) / 1000 / 60;
          if (detail.start == detail.end) {
            diff = 1440;
          }
          if (diff < 0) {
            diff = 1440 + diff;
          }
          let items = detail.items;
          if (items[items.length - 1].type == 0) {
            this.$alert('时段下至少有一个周期时长或按次计费！!', this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
            return false;
          }
          for (let i in items) {
            if (items[i].unit == 0 && items[i].type != 1) {
              this.$alert('区间单价不能为空或为0！！', this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine')
              });
              return false;
            }
            if (items[i].period == 0 && items[i].type != 1) {
              this.$alert('区间分钟不能为空或为0！！', this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine')
              });
              return false;
            }
            if (parseInt(items[i].period) != items[i].period) {
              this.$alert('区间分钟必须为整数！！', this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine')
              });
              return false;
            }
            if (items[i].period > diff) {
              this.$alert('区间分钟必须小于时段总分钟数！！', this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine')
              });
              return false;
            }
            if (items[i].type == 2 && i == 1 && items[i].period > diff - items[0].period) {
              this.$alert('同一时段下，基础时段和周期计价分钟总和不能大于该时段总分钟数！', this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine')
              });
              return false;
            }
          }
        }
      }
      return true;
    },
    sendHandle() {
      let data = Object.assign({}, this.formInline);
      data.freeTime = parseInt(data.freeTime);
      // this.errMsg = true;
      for (let i in data.rules) {
        delete data.rules[i].cid
        delete data.rules[i].errMsg
        delete data.rules[i].cdate
        delete data.rules[i].dis
        for (let j in data.rules[i].details) {
          delete data.rules[i].details[j].tStart
          delete data.rules[i].details[j].tEnd
          delete data.rules[i].details[j].cid
          for (let k in data.rules[i].details[j].items) {
            // data.rules[i].details[j].items[k].segment = parseInt(data.rules[i].details[j].items[k].segment);
            data.rules[i].details[j].items[k].period = data.rules[i].details[j].items[k].period * 1;
            data.rules[i].details[j].items[k].type = data.rules[i].details[j].items[k].type * 1;
            if (data.rules[i].details[j].items[k].period == 0 && data.rules[i].details[j].items[k].type == 2) {
              this.timeCheck = false;
            }
            delete data.rules[i].details[j].items[k].cid
          }
        }
      }
      this.checkTime(data)
      return data
    },
    checkTime(da) {
      let data = Object.assign({}, da);
      for (let i in data.rules) {
        let timePeriod = 0;
        for (let j in data.rules[i].details) {
          let timePeriod_ = 0;
          if (new Date("2017-1-1 " + data.rules[i].details[j].end).getTime() == new Date("2017-1-1 " + data.rules[i].details[j].start).getTime()) {
            timePeriod_ = 24 * 60 * 60;
          } else {
            if (new Date("2017-1-1 " + data.rules[i].details[j].end).getTime() == new Date("2017-1-1 00:00:00").getTime()) {
              timePeriod_ = 1 + Math.abs(new Date("2017-1-1 23:59:59").getTime() - new Date("2017-1-1 " + data.rules[i].details[j].start).getTime()) / 1000
            } else {
              timePeriod_ = Math.abs(1 * (new Date("2017-1-1 " + data.rules[i].details[j].end).getTime() - new Date("2017-1-1 " + data.rules[i].details[j].start).getTime())) / 1000
              if (1 * (new Date("2017-1-1 " + data.rules[i].details[j].end).getTime() - new Date("2017-1-1 " + data.rules[i].details[j].start).getTime()) < 0) {
                timePeriod_ = 0 - timePeriod_ + 24 * 60 * 60;
              }
            }
          }
          // if (data.rules[i].details[j].items[0].type == 1) {
          //     data.rules[i].details[j].items[0].period = timePeriod_ / 60;
          // }
          timePeriod += timePeriod_;
        }
        if (timePeriod != 24 * 60 * 60) {
          this.timeCheck = false;
          return;
        }
        da = data;
      }
    },
    setScroll() {
      this.$nextTick(() => {
        let doc = document.getElementById('content');
        doc.scrollTop = doc.scrollHeight - doc.clientHeight
      })
    },
    checkDate(rule, index) {
      let curDate = rule.cdate;
      let arr = [...this.formInline.rules];
      let tem = arr.splice(index + 1);
      // 当前日期大于后面日期
      let col = tem.filter((value, index) => {
        return new Date(curDate) - new Date(value.cdate) >= 0;
      })
      if (col.length) {
        rule.errMsg = '日期必须小于后面的日期！';
        return false;
      }
      arr = [...this.formInline.rules];
      tem = arr.splice(0, index);
      col = tem.filter((value, index) => {
        return new Date(curDate) - new Date(value.cdate) <= 0;
      })
      if (col.length) {
        rule.errMsg = '日期必须大于前面的日期！';
        return false;
      }
      return true;
    },

    timeChange(rule, index) {
      if (rule.cdate == '' || rule.cdate == undefined) {
        rule.errMsg = '生效日期不能为空'
        this.errMsg = false;
        return
      } else {
        rule.errMsg = ''
        this.errMsg = true;
      }
      rule.date = rule.cdate;
      this.errMsg = this.checkDate(rule, index);
    },
    checkNum(rule, value, callback) {
      if (value == 0) {
        return callback();
      }
      if (!/(\d+.\d+)|\d+/.test(value)) {
        return callback(new Error('必须为数字'));
      }
      if (!value) {
        callback(new Error('最高限额不能为空'));
      } else if (value < 0) {
        callback(new Error('最高收费限额应在0-999之间'));
      } else if (value > 999) {
        callback(new Error('最高收费限额应在0-999之间'));
      } else {
        callback();
      }
    },
    timeHandle(detail) {
      if (detail.tStart && detail.tStart.split(':')[2] != '00') {
        let tem = detail.tStart.split(':');
        tem[2] = '00';
        detail.tStart = tem.join(':');
      }
      if (detail.tEnd && detail.tEnd.split(':')[2] != '00') {
        let tem = detail.tEnd.split(':');
        tem[2] = '00';
        detail.tEnd = tem.join(':');
      }
      detail.start = !detail.tStart ? '' : detail.tStart;
      detail.end = !detail.tEnd ? '' : detail.tEnd;
    },
    dateFormat() {
    },
    removeRule(item) {
      var index = this.formInline.rules.indexOf(item)
      if (index !== -1) {
        this.formInline.rules.splice(index, 1)
      }
    },
    addRule(index) {
      this.formInline.rules.push({
        cid: 'form' + index,
        date: '',
        errMsg: '',
        cdate: '',
        details: [
          {
            cid: 'form' + index,
            tStart: '',
            tEnd: '',
            start: '',
            end: '',
            limit: '0',
            items: [
              {
                cid: 'form' + index,
                type: 0,
                // segment: '',
                threshold: 0,
                period: 0,
                unit: ''
              }
            ]
          }
        ]
      });
      this.setScroll();
    },
    detailOpreate(index, index1, detail) {
      if (this.formInline.rules[index].dis) {
        return;
      }
      if (!index1) {
        this.formInline.rules[index].details.push({
          tStart: '',
          tEnd: '',
          start: '',
          end: '',
          limit: '0',
          items: [
            {
              cid: 'form' + index,
              type: 0,
              // segment: '',
              threshold: 0,
              period: 0,
              unit: ''
            }
          ]

        })
      } else {
        var ind = this.formInline.rules[index].details.indexOf(detail)
        if (ind !== -1) {
          this.formInline.rules[index].details.splice(ind);
        }
      }
    },
    itemOpreate(index, index1, index2, item) {
      if (this.formInline.rules[index].dis) {
        return;
      }
      if (!index2) {
        this.formInline.rules[index].details[index1].items.push({
          cid: 'form' + index,
          type: 0,
          // segment: '',
          threshold: 0,
          period: 0,
          unit: ''
        })
      } else {
        let items = this.formInline.rules[index].details[index1].items;
        let len = items.length;
        var ind = items.indexOf(item)
        if (ind !== -1) {
          items.splice(ind, 1);
        }
        if (items[len].type == 0) {
          this.showAdd = true;
        }
      }
    },
    submitData() {
      this.Dateflag = false;
      this.$refs.ruleForm2.validate((valid) => {
        // 校验生效日期是否填写
        if (this.checkEffectiveDate()) {
        } else {
          this.$alert('请检查生效日期是否正确', this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
          return;
        }
        if (this.errMsg === true || (this.errMsg === '' && this.isEdit)) {
        } else {
          this.errMsg = true;
          this.$alert('请检查生效日期是否正确', this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
          return;
        }
        // this.errMsg === true 表示没有错误信息
        let data2 = this.sendHandle();
        data2.feeCode = data2.feeCode.replace(/\s/g, '');
        if (!this.checkIsNull(data2)) {
          this.timeCheck = true;
          this.getRuleHandle(JSON.parse(JSON.stringify(data2)), data2);
          return;
        }
        if (valid) {
          if (!this.timeCheck) {
            this.$alert('同一生效日期内，时段之和必须为24小时！', this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
            this.getRuleHandle(JSON.parse(JSON.stringify(data2)), data2);
            this.timeCheck = true;
            return
          }
          this.ruleSort(data2.rules);
          let data1 = {
            description: data2.description,
            feeName: data2.feeName,
            freeTime: data2.freeTime,
            feeCode: data2.feeCode,
            operationId: data2.operationId,
            source: JSON.stringify({ id: data2.id, freeTime: data2.freeTime, rules: data2.rules })
          }
          let method = this.isEdit ? 'post' : 'post';
          let url = !this.isEdit ? '/acb/2.0/fee/create' : '/acb/2.0/fee/update/' + this.feeId;
          this.$axios[method](url, {
            data: data1
          }).then(res => {
            if (res.state == 0) {
              this.$router.go(-1);
            } else {
              this.getRuleHandle(JSON.parse(JSON.stringify(data2)), data2);
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine')
              });
            }
          })
        } else {
          // console.log('error submit!!');
          if (this.errMsg != true) {
            this.$alert('提交失败，请保证信息无误', this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
          this.getRuleHandle(JSON.parse(JSON.stringify(data2)), data2);
          this.Dateflag = true;
          return false;
        }
      })
    },
    getRuleHandle(data, value) {
      data.freeTime = data.freeTime + '';
      for (let i in data.rules) {
        data.rules[i].cid = '0-0-0';
        data.rules[i].errMsg = ''
        data.rules[i].cdate = data.rules[i].date;
        if (i < this.isBind) {
          data.rules[i].dis = true;
        }
        for (let j in data.rules[i].details) {
          data.rules[i].details[j].tStart = !data.rules[i].details[j].start ? "" : data.rules[i].details[j].start;
          data.rules[i].details[j].tEnd = !data.rules[i].details[j].end ? "" : data.rules[i].details[j].end;
          data.rules[i].details[j].cid = '0-0-0';
          for (let k in data.rules[i].details[j].items) {
            data.rules[i].details[j].items[k].cid = '0-0-0';
            data.rules[i].details[j].items[k].type = Number(data.rules[i].details[j].items[k].type);
          }
        }
      }
      this.formInline = Object.assign({ operationId: value.operationId, feeName: value.feeName, feeCode: value.feeCode, description: value.description }, data);
    },
    ruleSort(rules) {
      rules.forEach(rule => {
        rule.details = rule.details.sort((detail1, detail2) => {
          return new Date('2018/1/1 ' + detail1.start) - new Date('2018/1/1 ' + detail2.start)
        })
      })
    },
    getRule() {
      this.$axios.get('/acb/2.0/fee/byId/' + this.feeId).then(res => {
        if (res.state == 0) {
          if (res.value.isBind) {
            this.isBind = JSON.parse(res.value.property).rules.length
          }
          let obj = res.value;
          obj.description = obj.description ? obj.description = obj.description.replace(/<br\/>/g, '\n') : '';
          this.getRuleHandle(JSON.parse(res.value.property), res.value);
          this.oldName = res.value.feeName
          this.oldCode = res.value.feeCode
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      })
    }
  },
  created() {
    this.getTenantList();
  },
  mounted() {
    if (this.$route.query.feeId) {
      this.isEdit = true;
      this.first1 = true;
      this.first2 = 2;
      this.feeId = this.$route.query.feeId;
      this.getRule()
    }
  }
}
</script>
<style scoped>
.tooltip1 {
  position: absolute !important;
  left: -65px;
  top: 8px;
  z-index: 1;
}
.fuhao {
  display: inline-block;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 16px;
  text-decoration: none;
  border: 2px solid;
  border-color: blue;
  font-size: 30px;
  vertical-align: middle;
}
.equipment .e_content {
  border: 1px solid #c0ccda;
  border-radius: 4px;
  min-height: 550px;
  padding: 0 20px;
  color: #48576a;
}
.equipment .e_content .f-top {
  width: 700px;
  margin: 0 auto;
  margin-bottom: 80px;
}
.el-select {
  width: 250px;
}
.breadcrumb {
  height: 35px;
}
.ruleMessage {
  padding: 10px;
  /* border: 1px solid #c0ccda; */
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin-bottom: 20px;
}
.title {
  line-height: 40px;
  padding-left: 20px;
}
.timerPiker {
  padding-left: 20px;
  position: relative;
}
.timerPiker .errMsg {
  position: absolute;
  left: 120px;
  top: 39px;
  font-size: 12px;
  color: #ff4949;
}
.effectDate {
  margin-right: 10px;
}
.dateForm {
  width: 700px;
  margin: 0 auto;
}
b {
  text-align: center;
  font-weight: normal;
  display: inline-block;
  width: 36px;
  line-height: 36px;
  background: #c0ccda;
}
.addEffictDate {
  display: inline-block;
  cursor: pointer;
}
.addEffictDate:hover {
  color: blue;
}
.help {
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  color: white;
  width: 20px;
  line-height: 20px;
  vertical-align: middle;
  background: #c0ccda;
  position: relative;
}
.timeMax label {
  padding: 0 !important;
}
</style>
